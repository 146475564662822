import { createStore } from 'vuex'
import http from "@/extensions/http";

export default createStore({
  state: {
    user: null,
    runs: null,
    completeRun: null,
    punchs: null,
    notifications: 0
  },
  getters: {
  },
  mutations: {
    resetState(state) {
      state.user = null;
      state.runs = null;
      state.completeRun = null;
      state.punchs = null;
      state.notifications = 0;
    },
    setUser(state, user) {
      state.user = user;
    },
    setRuns(state, runs) {
      state.runs = runs;
    },
    setSingleRun(state, run) {
      state.completeRun = run;
    },
    setTask(state, task) {
      state.completeRun.tasks[task.task_id] = task;
    },
    setTasks(state, tasks) {
      Object.values(tasks).forEach(task => {
        state.completeRun.tasks[task.task_id] = task;
      })
    },
    setClient(state, client) {
      state.completeRun.clients[client.id] = client;
    },
    setClients(state, clients) {
      state.completeRun.clients = clients;
    },
    setPunchs(state, punchs) {
      state.punchs = Object.assign({}, ...punchs.map((x) => ({[x.id]: x})));
    },
    setPunch(state, punch) {
      state.punchs[punch.id] = punch;
    },
    setNotifications(state, notif) {
      state.notifications = notif
    }
  },
  actions: {
    login(_, {username, password}) {
      return new Promise((resolve, reject) => {
        http.$post('/login', {
          username, password
        }).then(response => {
          const token = `Bearer ${response.data.token}`;
          http.defaults.headers.common['Authorization'] = token;
          localStorage.setItem('Authorization', token);
          resolve(response);
        }).catch(err => {
          reject(err);
        })
      })
    },
    me({commit}) {
      return new Promise((resolve, reject) => {
        http.$get('/me').then(response => {
          commit('setUser', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      })
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        http.$post('/logout').then(response => {
          localStorage.removeItem('Authorization')
          http.defaults.headers.common['Authorization'] = null
          commit("resetState");
          resolve(response);
        }).catch(err => {
          reject(err);
        })
      })
    },
    forgot(_, {username}) {
      return new Promise((resolve, reject) => {
        http.$post('/forgot', { username }).then(() => {
          resolve()
        }).catch(err => {
          reject(err);
        })
      })
    },
    reset(_, {email, token, password, password_confirmation}) {
      return new Promise((resolve, reject) => {
        http.$post('/reset', { email, token, password, password_confirmation }).then(() => {
          resolve()
        }).catch(err => {
          reject(err);
        })
      })
    },
    notifications({commit}) {
      return new Promise((resolve, reject) => {
        http.$getNotif('/notifications')
            .then(response => {
              commit('setNotifications', response.data.new_notes)
              resolve()
            }).catch(err => {
              reject(err);
            })
      })
    },
    runs({commit}) {
      return new Promise((resolve, reject) => {
        http.$get('/runs').then(response => {
          commit('setRuns', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    singleRun({commit}, { id }) {
      return new Promise((resolve, reject) => {
        http.$get('/runs/' + id).then(response => {
          commit('setSingleRun', response.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    toggleNoteReadStatus({commit}, {client_id, run_id}) {
      return new Promise((resolve, reject) => {
        http.$post(`/notes/toggle/${client_id}/${run_id}`).then((response) => {
          commit('setClient', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    reorderClientsList({commit}, {run_id, order}) {
      return new Promise((resolve, reject) => {
        http.$post(`/clients/reorder/${run_id}`, { order }).then(response => {
          commit('setClients', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    markClientAsSeen({commit}, {client_id, run_id}) {
      return new Promise((resolve, reject) => {
        http.$post(`/clients/${client_id}/markSeen/${run_id}`).then(response => {
          commit('setClient', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    sendNote({commit}, {note, task_id}) {
      return new Promise((resolve, reject) => {
        http.$post(`/tasks/${task_id}/note`, {note}).then(response => {
          commit('setTask', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    getPunchs({commit}) {
      return new Promise((resolve, reject) => {
        http.$get('/punchs').then(response => {
          commit('setPunchs', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    togglePunch({commit}) {
      return new Promise((resolve, reject) => {
        http.$post('/punchs').then(response => {
          commit('setPunch', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    },
    finishTask({commit}, {tasks_ids, signature = null}) {
      return new Promise((resolve, reject) => {
        http.$post(`/tasks/batch/completed`, signature ? { signature, tasks_ids } : {tasks_ids}).then(response => {
          commit('setTasks', response.data.data)
          resolve()
        }).catch(err => {
          reject(err);
        })
      });
    }
  },
  modules: {
  }
})
