import Router from "@/router";

const axios = require('axios')

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL })

axiosInstance.$get = url => new Promise((resolve, reject) => {
    axiosInstance.get(url)
        .then(response => resolve(response))
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('Authorization');
                axiosInstance.defaults.headers.common['Authorization'] = null;
                Router.push({ name: 'login' });
            }
            reject(error)
        })
})

axiosInstance.$post = (url, data = {}) => new Promise((resolve, reject) => {
    axiosInstance.post(url, data)
        .then(response => resolve(response))
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('Authorization');
                axiosInstance.defaults.headers.common['Authorization'] = null;
                Router.push({ name: 'login' });
            }
            reject(error)
        })
})

axiosInstance.$getNotif = url => new Promise((resolve, reject) => {
    if (axiosInstance.defaults.headers.common['Authorization']) {
        axiosInstance.get(url)
        .then(response => resolve(response))
        .catch((error) => reject(error))
    } else if (localStorage.getItem("Authorization")) {
        axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        axiosInstance.get(url)
        .then(response => resolve(response))
        .catch((error) => reject(error))
    }
})

export default axiosInstance
