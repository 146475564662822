import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const NotFound = () => import('@/views/NotFound')

function checkNotifications() {
    store.dispatch('notifications');
}

const routes = [
    {
        path: '/auth/login/:forgottenPassword?',
        name: 'login',
        component: () => import('@/views/Login')
    },
    {
        path: '/forgotten',
        name: 'forgotten',
        component: () => import('@/views/ForgottenPassword')
    },
    {
        path: '/reset',
        name: 'reset',
        component: () => import('@/views/ResetPassword')
    },
    {
        path: '/',
        component: () => import('@/views/Main'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/Home'),
                beforeEnter: [checkNotifications]
            },
            {
                path: '/punchs',
                name: 'punchs',
                component: () => import('@/views/Punchs'),
                beforeEnter: [checkNotifications]
            },
            {
                path: '/notes',
                name: 'notes',
                component: () => import('@/views/Notes'),
                beforeEnter: [checkNotifications]
            },
            {
                name: 'runs',
                path: '/runs/:run_id?/:interface?',
                component: () => import('@/views/Runs'),
                beforeEnter: [checkNotifications]
            },
            {
                path: '/client/:run_id/:client_id/:interface?',
                name: 'client',
                component: () => import('@/views/Client'),
                beforeEnter: [checkNotifications]
            }
        ]
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: '-active',
    routes
})

export default router
